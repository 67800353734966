@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined');

body {
    --secondary: #000000;
    --primary: #ffffff;
}

body.dark-mode {
    --secondary: #ffffff;
    --primary: #000000
}


body {
  margin: 0;
  font-family: 'Noto Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--primary);
  color: var(--secondary);
  text-align: center;
}

button {
    all: unset;
    cursor: pointer;
    background-color: var(--secondary);
    color: var(--primary);
    padding: 15px 50px;
    font-weight: bold;
}

table, tr, td, th {
    border: thin solid var(--secondary);
    border-collapse: collapse;
}

table {
    margin: 10px;
    height: 500px;
    overflow-y: scroll;
    display: inline-block;
}

td, th {
    padding: 10px;
}