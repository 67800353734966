/* Data Entry */
.data-entries {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.data-entry {
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.data-entry-name {
    text-align: left;
    margin: 20px;
    font-weight: bold;
}

/* ---- Edge ---- */
.data-edge {
    display: flex;
    flex-direction: row;
}

.data-node {
    margin: 5px;
}

.data-node, .data-edge-node {
    border: 3px solid var(--primary);
    padding: 15px;
    border-radius: 40px;
    line-height: 10px;
    width: 10px;
}

.data-edge-arrow {
    border-bottom: 3px solid var(--primary);
    width: 40px;
    margin: -10px 5px 20px 5px;
}

/* Array */

.data-array {
    display: flex;
    flex-direction: column;
}

.data-array-entry {
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.data-array-index {
    padding: 10px;
}

/* Set */
.data-set {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.data-set-entry {
    display: flex;
    flex-direction: row;
    line-height: 50px;
}