.number-input {

}

.number-input-placeholder {
    text-align: left;
}

.number-input-value {
    padding: 15px;
    font-size: 18px;
}