.row {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.spacer { flex-grow: 1; }